import React, { useEffect, useState, useCallback } from 'react';
import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { LeadershipPage } from '@shared/components/leadership-page';
import { LPContainer } from '@shared/contentstack';

const LeadershipPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>(null);

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'leadership_page',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;

    const result = response[0][0];
    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return !!livePreviewData ? (
    <LPContainer>
      <LeadershipPage pageContext={livePreviewData} />
    </LPContainer>
  ) : (
    <></>
  );
};

export default LeadershipPreviewPage;
